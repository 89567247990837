.client-suite-invite-content.client-suite-invite-content {
  width: auto !important;
  height: auto !important;

  .client-suite-invite-options {
    padding: 30px;
    color: #2a364c;
  }
}

.client-suite-invite-content.athena-invite {
  width: 70% !important;
  max-width: 700px;
}

.text-center {
  text-align: center;
}

.text-width {
  max-width: 150px;
  white-space: initial;
  align-items: center;
}

.text-right {
  text-align: end !important;
}

.color-black {
  color: black;
}

.color-white {
  color: #fff;
}
