.billing-content-view {
  width: 90%;
  margin: 0 auto;

  .bd-details-item-billing {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
    width: 50%;

    label {
      font-size: 1rem;
      text-align: right;
    }

    .add-bd-input-billing {
      font-size: 1rem;
      margin-bottom: 9px;
      width: 60%;
    }
  }
}
