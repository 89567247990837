.merge-pdlcontact {
  height: 400px;
  // overflow: auto;

  .popup-header {
    color: #5f51cb !important;
    background: none !important;
    font-weight: bold !important;
  }

  .ql-tooltip.ql-hidden {
    display: none !important;
  }

  .d-flex.align-items-start:first-child {
    padding: 0 !important;
  }

  .quill .ql-editor {
    padding-top: 0 !important;
    text-transform: capitalize;
  }

  .header-section {
    padding: 20px;
    display: flex;
    background-color: #f5f5f6;
    justify-content: space-between;
  }

  .message-container {
    width: 100%;
    border: 1px solid gray;
  }

  .unchecked {
    text-decoration: line-through;
  }

  .center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 25px;
  }

  .active {
    background: rgba(93, 89, 163, 0.15);
  }

  .click {
    cursor: pointer;
  }

  .view-container {
    overflow: auto;
    display: flex;
    background: #fff;
    height: calc(100% - 116px);

    .table-container {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  }

  .MuiTableContainer-root {
    width: 80%;
    overflow-x: auto;
  }

  .actions-container {
    display: flex;
    margin: 6px 20px;
    justify-content: flex-end;

    .merge-button {
      width: 100px;
      margin: 0 20px 0 30px;
    }
  }

  .table-cell {
    width: 37.5%;
    color: #2a364c;
    text-align: left;
    font-weight: 400;
    line-height: 1.5rem;
    display: table-cell;
    font-size: 0.875rem;
    word-break: break-word;
    vertical-align: inherit;
    letter-spacing: 0.01071em;
    padding: 6px 24px 6px 16px;
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    .MuiRadio-root {
      padding: 1px !important;
      padding-right: 5px !important;
    }
  }

  .MuiFormGroup-root {
    display: unset;
  }

  .table-header {
    .table-cell {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .sidebar-width {
    width: 20%;
  }

  .table-width {
    width: 85%;
  }

  .enrich-contact-popup {
    // height: 600px;
  }

  .table-cell-header {
    font-weight: 500;
    background-color: #f9f9ff;
  }

  .highlight {
    color: white;
    background-color: #8a84ff;
  }
}
