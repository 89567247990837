.merge-companies {
  height: 400px;
  // overflow: auto;

  .header-section {
    padding: 20px;
    display: flex;
    background-color: #f5f5f6;
    justify-content: space-between;
  }

  .view-container {
    overflow: auto;
    background: #fff;
    height: calc(100% - 116px);

    .table-container {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  }

  .actions-container {
    display: flex;
    margin: 6px 20px;
    justify-content: flex-end;

    .merge-button {
      width: 100px;
      margin: 0 20px 0 30px;
    }
  }

  .table-cell {
    width: 33.33%;
    color: #2a364c;
    text-align: left;
    font-weight: 400;
    line-height: 1.5rem;
    display: table-cell;
    font-size: 0.875rem;
    word-break: break-word;
    vertical-align: inherit;
    letter-spacing: 0.01071em;
    padding: 6px 24px 6px 16px;
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    .MuiRadio-root {
      padding: 1px !important;
      padding-right: 5px !important;
    }
  }

  .table-header {
    .table-cell {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .table-cell-header {
    font-weight: 500;
    background-color: #f9f9ff;
  }

  .highlight {
    color: white;
    background-color: #8a84ff;
  }
}
