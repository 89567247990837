.side-nav-item {
  padding: 10px 20px 10px 30px;
  border-left: 5px solid transparent;
  cursor: pointer;
}

.side-nav-item.active {
  border-left-color: #5d59a3;
}

.side-nav-item.active,
.side-nav-item:hover {
  background: rgba(93, 89, 163, 0.15);
}

.side-nav-text {
  font-size: 14px;
  line-height: 16px;
  color: #686a7e;
}

.side-nav-item.active .side-nav-text,
.side-nav-item:hover .side-nav-text {
  font-weight: bold;
  color: #1a1e3c;
}
