.athena-popover {
  min-width: 400px;
  max-width: 600px;
  padding: 10px;

  .title {
    font-size: 20px;
  }

  .content {
    padding: 10px;
    margin-top: 20px;

    .field {
      display: flex;
      margin-bottom: 20px;
      align-items: flex-end;

      .label {
        font-size: 14px !important;
        line-height: 16px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: rgba(92, 82, 198, 0.8);
        flex: 1;
        padding-right: 5px;
      }

      .value {
        font-size: 14px;
        line-height: 16px;
        color: #31394d;
        flex: 1 0;
        margin-bottom: 0 !important;
      }
    }
  }

  .invite-button {
    float: right;
    text-transform: none;
    margin: 0 10px 15px 0;
  }
}

.athena-button {
  font-size: 10px !important;
  padding: 5px 10px !important;
  min-width: 148px !important;
}
